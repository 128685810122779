import React, { useRef, useState } from "react"

// Import the necessary Framer functions
import { addPropertyControls, ControlType } from "framer"
/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight auto
 */
// Define the Calculator component
export default function Calculator(props) {
    const maxAmount = props.question1.maxValue
    const maxDuration = props.question2.maxValue

    const [amount, setAmount] = useState(props.question1.default)
    const [duration, setDuration] = useState(props.question2.default)
    const [amountThumbPos, setAmountThumbPos] = useState(
        (amount / maxAmount) * 100 + "%"
    )
    const [durationThumbPos, setDurationThumbPos] = useState(
        (duration / maxDuration) * 100 + "%"
    )

    const amountEle = useRef()
    const durationEle = useRef()
    const durationInput = useRef()
    const amountInput = useRef()

    const hanleDeposit = () => {
        const value = amountInput.current.value
        setAmount(amountInput.current.value)
        setAmountThumbPos((value / maxAmount) * 100 + "%")
    }

    const hanleDuration = () => {
        const value = durationInput.current.value
        setDuration(value)
        setDurationThumbPos((value / maxDuration) * 100 + "%")
    }

    let increament = Number(duration) * 50

    const evaluateFormula = (formula) => {
        try {
            // Replace Q1 and Q2 with their respective values
            const evaluatedFormula = formula
                .replace("Q1", amount)
                .replace("Q2", duration)

            // Use the eval function to calculate the result
            const result = eval(evaluatedFormula)

            // Ensure the result is a number and not NaN
            return isNaN(result) ? 0 : result
        } catch (error) {
            console.error("Error evaluating formula:", error)
            return 0
        }
    }

    // Calculate the result using the provided formula
    const result = evaluateFormula(props.formula)

    const styles = {
        box: {
            background: props.backgroundColor,
            borderRadius: "16px",
            padding: "40px",
            color: props.textColor,
            fontFamily: props.bodyFont.fontFamily,
        },
        title: {
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "110%",
            textAlign: "center",
            letterSpacing: "-1px",
            marginBottom: "40px",
        },
        subtitle: {
            fontFamily: "Figtree",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "170%",
            marginBottom: "10px",
            color: props.textColor,
        },
        totalBox: {
            borderRadius: "16px",
            background: props.totalboxColor,
            padding: "40px",
            textAlign: "center",
            marginTop: "40px",
        },
        totalBoxTitle: {
            fontSize: "40px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "110%",
            letterSpacing: "-2px",
            marginBottom: "8px",
            color: props.resultTextColor,
        },
        totalBoxPara: {
            fontFamily: "Figtree",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "120%",
            color: props.textColor,
        },
        rangeThumb: {
            backgroundColor: "#ffff",
            minWidth: "20px",
            borderRadius: "13px",
            position: "absolute",
            zIndex: 222,
            top: "50%",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#081717",
            fontFamily: "Figtree",
            fontSize: "12px",
            fontWeight: 600,
            lineHeight: "150%",
            padding: "3px 10px",
        },
        rangeWrap: {
            position: "relative",
            marginBottom: "32px",
        },
        progress: {
            background: "#FC3E13",
            width: "50%",
            position: "absolute",
            borderRadius: "0.5rem",
            height: "0.5rem",
            marginTop: "3px",
            bottom: "0",
            top: "0",
            transform: "translatey(-50%, -50%)",
            pointerEvents: "none",
        },
    }
    return (
        <>
            <style>
                {`
.range {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
}
.range:focus {
  outline: none;
}
.range::-webkit-slider-runnable-track {
  background-color: #081717;
  opacity: 0.3;
  border-radius: 0.5rem;
  height: 0.5rem;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: -12px;
  background-color: transparent;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.range:focus::-webkit-slider-thumb {
  border: none;
  outline: none;
  outline-offset: none;
}
.range::-moz-range-track {
  background-color: #081717;
  opacity: 0.3;
  border-radius: 0.5rem;
  height: 0.5rem;
}
.range::-moz-range-thumb {
  border: none;
  border-radius: 0;
  background-color: transparent;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.range:focus::-moz-range-thumb {
  border: none;
  outline: none;
  outline-offset: none;
}`}
            </style>
            <div className="box" style={styles.box}>
                <h1 className="slideTitle" style={styles.title}>
                    {props.title}
                </h1>
                <h4 className="slideSubTitle" style={styles.subtitle}>
                    {props.question1.title} ({amount})
                </h4>

                <div className="rangeWrap" style={styles.rangeWrap}>
                    <input
                        className="range"
                        ref={amountInput}
                        type="range"
                        min={props.question1.minValue}
                        max={maxAmount}
                        value={amount}
                        step={props.question1.incValue}
                        onInput={hanleDeposit}
                    />
                    <div
                        className="progress"
                        style={{ ...styles.progress, width: amountThumbPos }}
                    ></div>
                    <div
                        className="rangeThumb"
                        ref={amountEle}
                        style={{ ...styles.rangeThumb, left: amountThumbPos }}
                    >
                        {amount}
                    </div>
                </div>

                <h4 className="slideSubTitle" style={styles.subtitle}>
                    {props.question2.title} ({duration}{" "}
                    {props.question2.dynamicDataName})
                </h4>
                <div className="rangeWrap" style={styles.rangeWrap}>
                    <input
                        className="range"
                        ref={durationInput}
                        type="range"
                        min={props.question2.minValue}
                        step={props.question2.incValue}
                        max={maxDuration}
                        value={duration}
                        onInput={hanleDuration}
                    />
                    <div
                        className="progress"
                        style={{ ...styles.progress, width: durationThumbPos }}
                    ></div>
                    <div
                        className="rangeThumb"
                        ref={durationEle}
                        style={{ ...styles.rangeThumb, left: durationThumbPos }}
                    >
                        {duration}
                    </div>
                </div>

                <div className="totalBox" style={styles.totalBox}>
                    <h2 style={styles.totalBoxTitle}>
                        $
                        {result === 0
                            ? Number(amount) === 0
                                ? 0
                                : Number(amount) + Number(increament)
                            : result}
                    </h2>
                    <p style={styles.totalBoxPara}>Total cost per month</p>
                </div>
            </div>
        </>
    )
}
addPropertyControls(Calculator, {
    title: {
        type: ControlType.String,
        title: "Title",
        defaultValue: "How will your money grow?",
    },
    question1: {
        type: ControlType.Object,
        title: "Question 1",
        controls: {
            title: {
                type: ControlType.String,
                title: "Title",
                defaultValue: "Deposit Per Month",
            },
            default: {
                type: ControlType.Number,
                title: "Default Value",
                defaultValue: 0,
            },
            minValue: {
                type: ControlType.Number,
                title: "Min Value",
                defaultValue: 0,
            },
            maxValue: {
                type: ControlType.Number,
                title: "Max Value",
                defaultValue: 100,
            },
            incValue: {
                type: ControlType.Number,
                title: "Inc Value",
                defaultValue: 10,
            },
        },
    },
    question2: {
        type: ControlType.Object,
        title: "Question 2 (Q2)",
        controls: {
            title: {
                type: ControlType.String,
                title: "Title",
                defaultValue: "Deposit Per Month",
            },
            dynamicDataName: {
                type: ControlType.String,
                title: "Dynamic Data Name",
                defaultValue: "Month",
            },
            default: {
                type: ControlType.Number,
                title: "Default Value",
                defaultValue: 0,
            },
            minValue: {
                type: ControlType.Number,
                title: "Min Value",
                defaultValue: 0,
            },
            maxValue: {
                type: ControlType.Number,
                title: "Max Value",
                defaultValue: 100,
            },
            incValue: {
                type: ControlType.Number,
                title: "Inc Value",
                defaultValue: 10,
            },
        },
    },
    formula: {
        type: ControlType.String,
        title: "Formula",
        defaultValue: "(Q1 * Q2) % 10",
    },
    bodyFont: {
        title: "Body Font",
        type: ControlType.Font,
        controls: "family",
    },
    textColor: {
        type: ControlType.Color,
        title: "Text Color",
        defaultValue: "#ffffff",
    },
    resultTextColor: {
        type: ControlType.Color,
        title: "Result Text Color",
        defaultValue: "#000000",
    },
    backgroundColor: {
        type: ControlType.Color,
        title: "Background Color",
        defaultValue: "#217376",
    },
    totalboxColor: {
        type: ControlType.Color,
        title: "Total box Color",
        defaultValue: "#ffffff",
    },
})
